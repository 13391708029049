import React, {FunctionComponent} from "react";
import TrendingList from "../trending-list";
import Bio from "../bio";

const SidebarContent: FunctionComponent = () => {
  return (
    <>
      <Bio textAlign={'left'} />
      <TrendingList />
    </>
  );
};

export default SidebarContent;
