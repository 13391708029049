import React, {FunctionComponent} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {Category} from "../../utils/models";
import Img from "gatsby-image";
import slugify from "slugify";
import {
  StyledTag,
  StyledTagList,
  TagArchiveLink,
  TagArchiveLinkWrapper,
  TagContainer,
  TagIcon,
  TagListTitle,
  TagName,
} from "./style";

const CategoryList: FunctionComponent = () => {
  const categoriesQuery = useStaticQuery<{ categories: { nodes: Category[] } }>(graphql`
    query Categories {
      categories: allCategoriesYaml(filter: {featured: { eq: true }}) {
        nodes {
          name
          icon {
            childImageSharp {
              fixed(height: 55) {
                ...GatsbyImageSharpFixed
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  `);
  const categories      = categoriesQuery.categories.nodes;

  return (
    <TagContainer>
      <TagListTitle>Categories</TagListTitle>
      <StyledTagList>
        {categories.map((category, index) => {
          const icon = category.icon;
          return (
            <StyledTag key={index}>
              <Link to={`/category/${slugify(category.name, {lower: true})}`}>
                {/* gatsby-image doesn't handle SVGs, hence we need to take care of it */}
                {icon.extension !== 'svg'
                  ? <Img fixed={category.icon.childImageSharp.fixed}/>
                  : <TagIcon src={icon.publicURL} alt={category.name}/>
                }
                <TagName>{category.name}</TagName>
              </Link>
            </StyledTag>
          );
        })}
      </StyledTagList>
      <TagArchiveLinkWrapper>
        <TagArchiveLink to={`/category`}>See all categories</TagArchiveLink>
      </TagArchiveLinkWrapper>
    </TagContainer>
  );
};

export default CategoryList;
